import React from "react";
import { Link } from "react-router-dom";
import "./ListNFTs.css";
import { RequestManager, HTTPTransport, Client } from "@open-rpc/client-js";
const transport = new HTTPTransport(process.env.REACT_APP_RPC_URL);
const client = new Client(new RequestManager([transport]));
function Comp({ token }) {
  const [perks, setPerks] = React.useState([]);
  React.useEffect(() => {
    const getPerks = async () => {
      const remotePerks = await client.request({ method: "nftperks.getperks", params: { nftid: token.toString(10) } });
      setPerks(remotePerks.data);
      // debugger;
    }
    getPerks();
  }, [])


  if (!token || !perks) {
    return null;
  }
  return (
    <>
      <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">AVADO NFT #{token.toString(10)}</h1>
      {perks.map(perk => {
        console.log(perk)
        return (
          <div class="box content">
            <article class="post">
              <h4>{perk.title}</h4>
              <div class="media">
                {/* <div class="media-left">
                  <p class="image is-32x32">
                    <img src="http://bulma.io/images/placeholders/128x128.png" />
                  </p>
                </div> */}
                <div class="media-content">
                  <div class="content">
                    <p>
                      {perk.description}
                      {/* <a href="#">@jsmith</a> replied 34 minutes ago &nbsp; */}
                      {/* <span class="tag">Question</span> */}
                    </p>
                  </div>
                </div>
                <div class="media-right">
                  {perk.claimed ? (
                    <span class="tag is-size-6 is-success">Already claimed</span>
                  ) : (
                    <Link
                      smooth
                      className="button buttonmargin is-primary is-medium"
                      to={`${perk.url}/${token.toString(10)}`}
                    >
                      Claim!
                    </Link>
                  )}
                  {/* <button className="button buttonmargin is-primary is-medium">Claim!</button> */}
                  {/* <span class="has-text-grey-light"><i class="fa fa-comments"></i> 1</span> */}
                </div>
              </div>
            </article>
          </div>
        );
      })}

    </>
  );
}

export default Comp;
