import React from "react";
import { Link } from "react-router-dom";
import { useContractReader } from "eth-hooks";
import { ethers } from "ethers";
import NFTAbi from "../../assets/nft-abi.json";
import { BigNumber } from "ethers";
import NoNFTs from "./NoNFTs";
import ListNFTs from "./ListNFTs";
// import AddTokenToMetamask from "./AddTokenToMetamask"

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 */
function Home({ address, readContracts, localProvider }) {
  // you can also use hooks locally in your component of choice
  // in this case, let's keep track of 'purpose' variable from our contract
  // const purpose = useContractReader(readContracts, "YourContract", "purpose");

  const [tokens, setTokens] = React.useState();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const load = async () => {
      if (address && readContracts && readContracts.AVADONFT) {
        const balance = BigNumber.from(await readContracts.AVADONFT.balanceOf(address)).toNumber();
        let tokens = [];
        for (let i = 0; i < balance; i++) {
          const tokenID = await readContracts.AVADONFT.tokenOfOwnerByIndex(address, i);
          tokens.push(tokenID);
        }
        setLoading(false);
        setTokens(tokens);
        // debugger;
      }
    };
    load();
  }, [readContracts, address]);

  //   React.useEffect(() => {
  //     if (!provider || !provider.provider) return;
  //     setMintAccount(provider.provider.selectedAddress);
  //     provider.provider.on("accountsChanged", (accounts) => {
  //         setMintAccount(provider.provider.selectedAddress);
  //     });
  // }, [provider]);

  // const getNFTof = async (address) => {
  //     debugger;
  //     try {
  //         const provider = new ethers.providers.JsonRpcProvider(config.eth.rpcendpoint);
  //         // const walletWithProvider = new ethers.Wallet(config.eth.signer, provider);
  //         const nft = new ethers.Contract(config.eth.nftcontractaddress, NFTAbi, provider);

  //         const tokenId = await nft.tokenOfOwnerByIndex(address, 0);    // big int
  //         const tokenURI = (await nft.tokenURI(tokenId)).replace("ipfs://", "");
  //         const index = tokenId.toNumber();
  //         // const tokenData = (await axios.get(`https://ipfs.web3.party/ipfs/${tokenURI}`)).data;
  //         setTokenData({
  //             index: index,
  //             // data: tokenData,
  //             uri: tokenURI,
  //         });
  //         // setTokenIndex(index+1);

  //         // debugger;


  //     } catch (e) {
  //         console.log(`no NFT for address ${address}`);
  //     }
  //     // debugger;

  // }

  // debugger;
  // console.log(
  // const AVADONFT =     readContracts.externalContracts.gnosis.contracts.AVADONFT;
  // );
  // debugger;

  if (!address) {
    return (
      <section className="section hero is-medium">
        <div className="hero-body is-vcentered">
          <div className="container has-text-centered">
            <div className="columns">
              <div className="column is-three-fifths hero-image">
                <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">AVADO NFT portal</h1>
                <br />
                <p className="is-size-4 is-size-6-mobile has-text-light">
                  Claim perks on your AVADO NFT
                  <br />
                </p>
              </div>
              <div className="column is-two-fifths">
                <p className="is-size-4 is-size-6-mobile has-text-light">
                  Please use the "Connect" button on top to connect to your wallet and select the wallet that holds your AVADO nft
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (loading) {
    return <>Loading your AVADO nft's</>;
  }

  if (tokens && tokens.length === 0) {
    return <NoNFTs />;
  }
  if (tokens && tokens.length > 0) {
    return (
      <>
        <ListNFTs tokens={tokens} />
        {/* <AddTokenToMetamask localProvider={localProvider} /> */}
      </>
    );
  }

  return <>Error?</>;
}

export default Home;
