/* eslint-disable prettier/prettier */
import React from "react";
// import GNOlogo from "../../assets/gnosis-gno-gno-logo-circle.svg";
// import { BigNumber } from "ethers";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import { validate } from "email-validator";
import { RequestManager, HTTPTransport, Client } from "@open-rpc/client-js";
import "./GNO.css";

const hash = require('json-hash');

const transport = new HTTPTransport(process.env.REACT_APP_RPC_URL);
const client = new Client(new RequestManager([transport]));

function GNO({ address, readContracts, provider }) {
    let { id } = useParams();

    const [file, setFile] = React.useState(null);
    const [fileData, setFileData] = React.useState(null);
    const [email, setEmail] = React.useState();
    const [formReady, setFormReady] = React.useState(false);
    const [formMessage, setFormMessage] = React.useState();
    const [formError, setFormError] = React.useState();

    React.useEffect(() => {
        setFormError(null);
        setFormMessage(null);
        if (id && file && email && validate(email)) {
            setFormReady(true);
        } else {
            setFormReady(false);
        }
    }, [id, file, email]);

    const handleChange = file => {
        const getBase64 = file => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setFile(file);
                setFileData(reader.result);
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        };
        getBase64(file);
    };

    const submit = async () => {
        setFormError(null);
        setFormMessage(null);
        const perkdata = { filedata: fileData, nftid: id, perkname: "6gno", email }
        const perkdatahash = hash.digest(perkdata);
        try {
            const signature = await provider.provider.send("personal_sign", [`claim perk ${perkdatahash}`, address]);
            const res = await client.request({
                method: "nftperks.claimperk", params: {
                    nftid: id, perkname: "6gno", perkdata, signature
                }
            });
            if (res.error) {
                setFormError(res.error);
                setFormMessage(null);
            } else {
                setFormMessage(res.message);
                setFormError(null);
            }
        } catch (e) {
            setFormError("Failed to get crypto signature");
        };
    }

    if (!address) {
        return (
            <section className="section hero is-medium">
                <div className="hero-body is-vcentered">
                    <div className="container has-text-centered">
                        <div className="columns">
                            <div className="column is-three-fifths hero-image">
                                <h1 className="is-size-4 is-size-4-mobile has-text-centered-mobile has-text-light">Please connect to your wallet first</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }


    return (
        <section className="section hero is-medium">
            <div className="hero-body is-vcentered">
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column is-three-fifths hero-image">
                            <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">GNO validator drop</h1>
                            <p className="is-size-4 is-size-6-mobile has-text-light">
                                for NFT #{id}
                                <br />
                            </p>
                            <br/>
                            <p className="has-text-light is-size-5">Make sure that you:</p>
                            <p className="has-text-light">Have installed the AVADO Gnosis Validator package ✔</p>
                            <p className="has-text-light">Have installed the AVADO Gnosis Beacon chain package ✔</p>
                            <p className="has-text-light">Have installed the AVADO Gnosis Key Generator package ✔</p>
                            
                        </div>
                        <div className="column is-two-fifths">
                            <p className="is-size-4 is-size-6-mobile has-text-light">
                                Create a single deposit file with 1, 4 or 6 validators using the AVADO gnosis key generator and upload
                                the deposit file here.
                            </p>
                            <br />
                            <FileUploader
                                label="Select a deposit file - or drop it here"
                                classes="uploader"
                                handleChange={handleChange}
                                name="file"
                                types={["json"]}
                            />
                            {file ? (
                                <p className="has-text-light">{file.name}</p>
                            ) : (
                                <p className="has-text-light">(File should be called deposit_data-xxxxxxxxxx.json)</p>
                            )}
                            <br />
                            <div className="field">
                                <label className="label has-text-light">E-mail address you used to subscribe</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        onChange={e => {
                                            setEmail(e.target.value);
                                        }}
                                        placeholder="e-mail"
                                    />
                                </div>
                                <p className="has-text-light">(so we can verify that you subscribed to the campaign, and contact you when the deposit has been done)</p>
                            </div>
                            <button disabled={!formReady} className="button is-success is-medium" onClick={submit}>
                                Upload file
                            </button>
                            {formError && (
                                <>
                                    <br /><br />
                                    <div class="notification is-danger">
                                        <button onClick={() => { setFormError(null) }} class="delete"></button>
                                        {formError}
                                    </div>
                                </>
                            )}
                            {formMessage && (
                                <>
                                    <br /><br />
                                    <div class="notification is-success">
                                        <button onClick={() => { setFormMessage(null) }} class="delete"></button>
                                        {formMessage}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GNO;
