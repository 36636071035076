import React from "react";
import { Link } from "react-router-dom";

function Comp({ address, readContracts }) {
  return (
    <section className="section hero is-medium">
      <div className="hero-body is-vcentered">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column is-three-fifths hero-image">
              <h1 className="is-size-1 is-size-4-mobile has-text-centered-mobile has-text-light">
                SNAP!
                <br />
                No NFT's here
              </h1>
              <br />
              <p className="is-size-4 is-size-6-mobile has-text-light">
                You have no AVADO NFT's on this address.
                <br />
              </p>
            </div>
            <div className="column is-two-fifths">
              <p className="is-size-4 is-size-6-mobile has-text-light">
                Select a different address or</p>
                <a className="button buttonmargin is-primary is-medium" rel="noreferrer" target="_blank" href="https://nft.ava.do">mint your NFT</a>
                <p className="is-size-4 is-size-6-mobile has-text-light">
                if you haven't done so already.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Comp;
