// import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";


// const client = new ApolloClient({
//   uri: subgraphUri,
//   cache: new InMemoryCache(),
// });

ReactDOM.render(
<>
<BrowserRouter>
        <App  />
      </BrowserRouter>
      </>
  ,
  document.getElementById("root"),
);
