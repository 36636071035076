import React from "react";
import "./ListNFTs.css";
import NFTWithPerks from "./NFTWithPerks";

function Comp({ tokens }) {
  if (!tokens) {
    return null;
  }
  return (
    <section className="section hero is-medium">
      <div className="hero-body is-vcentered">
        <div className="container">
          {tokens.map(token => {
            return (
              <>
                <NFTWithPerks token={token} />;
              </>
            );
          })}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default Comp;
